<template>
  <section id="email-sent">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Consultor"
                label-for="manager"
              >
                <b-form-select
                  id="manager"
                  v-model="filtersValues.manager"
                  :options="managersOptions"
                  :disabled="isLoading || filtersValues.type === 'system'"
                />
              </b-form-group>
              <b-form-group
                label="Data de envio"
                label-for="sent-date"
              >
                <flat-pickr
                  id="sent-date"
                  ref="datePickerSentDate"
                  v-model="filtersValues.sentDate"
                  class="form-control"
                  :config="pickrConfig"
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Destinatário"
                label-for="to"
              >
                <b-form-input
                  id="to"
                  v-model="filtersValues.to"
                  :disabled="isLoading"
                />
              </b-form-group>
              <b-form-group
                label="Assunto"
                label-for="subject"
              >
                <b-form-input
                  id="subject"
                  v-model="filtersValues.subject"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              class=""
            >
              <b-form-group
                label="Tipo"
                label-for="type"
              >
                <b-form-select
                  id="type"
                  v-model="filtersValues.type"
                  :options="emailTypeOptions"
                  :disabled="isLoading"
                  @change="clearResults"
                />
              </b-form-group>

              <b-form-group
                label="Disparador"
                label-for="sender"
              >
                <b-form-select
                  id="sender"
                  v-model="filtersValues.sender"
                  :options="emailSenderOptions"
                  :disabled="isLoading || filtersValues.type === 'individual'"
                  @change="clearResults"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="form-buttons d-flex justify-content-end"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  :disabled="isLoading"
                  variant="primary"
                  @click="getEmails()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  :disabled="isLoading"
                  variant="outline-secondary"
                  @click="resetFilters()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                responsive
                :items="results"
                :fields="result_fields"
                show-empty
                no-local-sorting
              >
                <template #empty>
                  <h4 class="text-center mt-2 mb-2">
                    Nenhum resultado encontrado
                  </h4>
                </template>

                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(created_at)>
                  <div class="text-nowrap">
                    Data
                  </div>
                </template>

                <template #head(subject)>
                  <div class="text-nowrap">
                    Assunto
                  </div>
                </template>

                <template #head(from)>
                  <div class="text-nowrap">
                    Remetente
                  </div>
                </template>

                <template #head(to)>
                  <div class="text-nowrap">
                    Destinatário
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #cell(created_at)="row">
                  <div class="text-nowrap">
                    {{ row.item.created_at ? formatDateDbToView(row.item.created_at) : '' }}
                    {{ row.item.sent_at ? formatDateDbToView(row.item.sent_at) : '' }}
                  </div>
                  <div
                    v-if="row.item.created_at"
                    class="text-nowrap"
                  >
                    <b-badge
                      :title="row.item.created_at"
                      :variant="{
                        created: 'primary',
                        delivered: 'success',
                        sent: 'success',
                        error: 'danger',
                      }[row.item.status]"
                    >
                      {{ $t(`emails.columns.status.${row.item.status}`) }}
                    </b-badge>
                  </div>
                  <div
                    v-if="row.item.created_at && row.item.mandrill_status"
                    class="text-nowrap"
                  >
                    <b-badge
                      :variant="{
                        created: 'primary',
                        delivered: 'success',
                        sent: 'success',
                        error: 'danger',
                        reject: 'danger',
                      }[row.item.mandrill_status]"
                    >
                      {{ $t(`emails.columns.mandrill_status.${row.item.mandrill_status}`) }}
                    </b-badge>
                  </div>

                  <div
                    v-if="row.item.sent_at"
                    class="text-nowrap"
                  >
                    <b-badge
                      :title="row.item.created_at"
                      :variant="{
                        delivered: 'success',
                        bounced: 'danger',
                        failed: 'danger',
                        deferred: 'danger',
                      }[row.item.status]"
                    >
                      {{ $t(`emails.columns.status.${row.item.status}`) }}
                    </b-badge>
                  </div>

                  <div class="text-nowrap">
                    <b-badge
                      v-if="row.item.open_at"
                      :title="row.item.open_at"
                      variant="info"
                    >
                      Aberto
                    </b-badge>
                  </div>

                  <div class="text-nowrap">
                    <b-badge
                      v-if="row.item.click_at"
                      :title="row.item.click_at"
                      variant="info"
                    >
                      Link Clicado
                    </b-badge>
                  </div>
                </template>

                <template #cell(subject)="row">
                  <div>
                    <feather-icon
                      v-if="row.item.attachment_name && row.item.attachment_name !== 'null'"
                      icon="PaperclipIcon"
                    /> ({{ row.item.id }}) {{ row.item.subject }}
                  </div>
                </template>

                <template #cell(from)="row">
                  <div
                    class="d-flex align-items-start justify-content-start"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.manager && row.item.manager.avatar ? row.item.manager.avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="row.item.manager && !row.item.manager.name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      ({{ row.item.manager && row.item.manager.id ? row.item.manager.id : '' }})
                      {{ row.item.manager && row.item.manager.name ? row.item.manager.name : '' }}<br>
                      {{ row.item.from }}
                    </div>
                  </div>
                </template>

                <template #cell(to)="row">
                  <div
                    class="d-flex align-items-start justify-content-start"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.user && row.item.user.avatar ? row.item.user.avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="row.item.user && !row.item.user.name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      ({{ row.item.user ? row.item.user.id : '' }})
                      {{ row.item.user ? row.item.user.name : '' }}<br>
                      {{ row.item.to }}
                    </div>
                  </div></template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      variant="flat-primary"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.user ? row.item.user.id : '' } }"
                      title="Ver destinatário"
                      target="_blank"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                    <b-button
                      v-if="filtersValues.type === 'individual'"
                      variant="flat-primary"
                      title="Ver mensagem"
                      @click="viewMessage(row.item)"
                    >
                      <feather-icon
                        icon="MailIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row
            v-if="!isLoading"
          >
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                base-url="#"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      ref="modal-view-message"
      hide-header
      hide-footer
      size="lg"
    >
      <div
        v-if="modalAttachmentName && modalAttachmentName !== 'null'"
      >
        <h4 class="mb-1">
          Anexo
        </h4>
        <span>
          {{ modalAttachmentName }}
        </span>
        <b-button
          variant="outline-primary"
          class="btn-icon"
          size="sm"
          @click="downloadAttachment"
        >
          <feather-icon icon="DownloadIcon" />
        </b-button>
        <hr />
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <h4
        class="mb-1"
      >
        Mensagem
      </h4>
      <div
        v-html="
          modalMessage"
      />
      <!-- eslint-enable vue/no-v-html -->
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BBadge,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCol,
  BSpinner,
  BBreadcrumb,
  BCard,
  BTable,
  BPaginationNav,
  BAvatar,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

import EmailService from '@/services/emailService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  managersOptions, emailSender,
} from '@/utils/options'
import { formatDateDbToView, formatDateTimeDbToView } from '@/utils/helpers'
import downloadFile from '@/mixins/downloadFile'

export default {
  components: {
    BRow,
    BBadge,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCol,
    BSpinner,
    BBreadcrumb,
    BCard,
    BTable,
    BPaginationNav,
    BAvatar,
    BCardActions,
    flatPickr,
  },
  mixins: [downloadFile],
  data() {
    const defaultFilterValues = {
      to: '',
      manager: '',
      sentDate: '',
      subject: '',
      sender: 'mandrill',
      type: 'individual',
    }
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'E-mails',
          active: true,
        },
        {
          text: 'Enviados',
          active: true,
        },
      ],
      pickrConfig: {
        mode: 'range',
        allowInput: false,
        position: 'auto center',
        dateFormat: 'Y-m-d',
        altFormat: 'd/m',
        altInput: true,
        locale: Portuguese,
      },
      isLoading: false,
      managersOptions,
      emailTypeOptions: [
        {
          text: 'Enviado por consultor',
          value: 'individual',
        },
        {
          text: 'Sistema',
          value: 'system',
        },
      ],
      emailSenderOptions: [
        ...emailSender,
      ],
      defaultFilterValues,
      filtersValues: {
        ...defaultFilterValues,
      },
      pages: {},
      currentPage: 1,
      results: [],
      isSubmited: false,
      formatDateDbToView,
      formatDateTimeDbToView,
      modalMessage: '',
      modalAttachmentName: '',
      modalAttachmentPath: '',
    }
  },
  computed: {
    result_fields() {
      return [
        { key: 'created_at', sortable: false, thStyle: 'width: 120px' },
        { key: 'subject', sortable: false },
        this.filtersValues.type === 'individual' ? { key: 'from', sortable: false } : null,
        { key: 'to', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 120px' },
      ]
    },
  },
  watch: {
    currentPage() {
      this.getEmails()
    },
  },
  methods: {
    downloadAttachment() {
      const emailService = new EmailService()
      emailService.getUrlDownloadAttachment(this.modalAttachmentName, this.modalAttachmentPath).then(response => {
        this.openModalToSaveFile(response, this.modalAttachmentName)
      })
    },

    selectedType(e) {
      if (e === 'individual') {
        this.filtersValues.sender = 'mandrill'
      }
    },
    viewMessage(email) {
      this.modalAttachmentName = email.attachment_name
      this.modalAttachmentPath = email.attachment_path
      this.modalMessage = email.message
      this.$refs['modal-view-message'].show()
    },
    clearResults() {
      this.results = []
      this.isSubmited = false
      this.pages = {}
    },
    resetFilters() {
      this.filtersValues = {
        ...this.defaultFilterValues,
      }

      this.clearResults()
    },
    getEmails() {
      if (this.isLoading) {
        return false
      }

      this.isLoading = true
      this.isSubmited = true

      const data = { ...this.filtersValues }

      if (data.sentDate.includes(' até ')) {
        [data.sentDateFrom, data.sentDateTo] = data.sentDate.split(' até ')
      } else {
        data.sentDateFrom = data.sentDate
      }

      EmailService.listSentEmail(data, this.currentPage)
        .then(response => {
          if (response.data !== null) {
            this.results = response.data.data.email.data

            if (this.results.length === 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ops!',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: 'Nenhum resultado encontrado com os termos pesquisados',
                },
              })
            }

            this.pages = {
              ...response.data.data.email,
              data: null,
            }
          }

          this.isLoading = false
        })

      return true
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
